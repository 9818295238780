import { fetchData } from './helpers/fetchData';
import { alertConfirm, alertGeneric } from './helpers/swalAlerts';

const bonusBookingFunctions = () => {
    const content_bonus = document.querySelector('.content_bonus');
    if (!content_bonus) return;

    const data_ubicacion = content_bonus.getAttribute('data-type-booking');

    // Función para cambiar el color de fondo del bonus_container según el estado del checkbox
    const updateBonusContainerColor = (checkbox) => {
        const bonus_container = checkbox.parentElement;
        if (checkbox.checked) {
            bonus_container.style.backgroundColor = '#ccdfff';
            //añadirle la clase active
            bonus_container.classList.add('active');
        } else {
            bonus_container.style.backgroundColor = '';
            //removerle la clase active
            bonus_container.classList.remove('active');
        }
    };

        // Función para dibujar la caja de selección
        const drawSelectionBox = (startX, startY, currentX, currentY) => {
            let selectionBox = document.querySelector('.selection-box');
            if (!selectionBox) {
                selectionBox = document.createElement('div');
                selectionBox.classList.add('selection-box');
                selectionBox.style.position = 'absolute';
                selectionBox.style.border = '2px dashed rgba(0, 123, 255, 0.7)';
                selectionBox.style.backgroundColor = 'rgba(0, 123, 255, 0.1)';
                selectionBox.style.zIndex = '9999';
                document.body.appendChild(selectionBox);
            }
    
            const width = Math.abs(currentX - startX);
            const height = Math.abs(currentY - startY);
            const left = Math.min(currentX, startX);
            const top = Math.min(currentY, startY);
    
            selectionBox.style.width = `${width}px`;
            selectionBox.style.height = `${height}px`;
            selectionBox.style.left = `${left}px`;
            selectionBox.style.top = `${top}px`;
    
            return selectionBox;
        };
    
        // Eliminar la caja de selección
        const removeSelectionBox = () => {
            const selectionBox = document.querySelector('.selection-box');
            if (selectionBox) {
                document.body.removeChild(selectionBox);
            }
        };

    // Función que maneja la selección de checkboxes arrastrando sobre los contenedores
    // const handleDragSelection = () => {
    //     let isDragging = false;
    //     let startContainer = null;
    //     const selectedContainers = new Set();

    //     // Función para seleccionar/deseleccionar el checkbox dentro del contenedor
    //     const toggleCheckboxInContainer = (container, shouldCheck) => {
    //         const checkbox = container.querySelector('input[type="checkbox"]');
    //         if (checkbox && !checkbox.disabled) {
    //             checkbox.checked = shouldCheck;
    //             updateBonusContainerColor(checkbox);
    //         }
    //     };

    //     // Manejador del evento mousedown
    //     const handleMouseDown = (e) => {
    //         // Si el clic es directamente sobre el checkbox, no activamos la lógica de arrastre
    //         if (e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
    //             return;
    //         }

    //         const container = e.target.closest('.bonus_container');
    //         if (container) {
    //             isDragging = true;
    //             startContainer = container;
    //             selectedContainers.clear();
    //             selectedContainers.add(startContainer);

    //             toggleCheckboxInContainer(startContainer, true); // Marcar checkbox al iniciar arrastre

    //             // Evita seleccionar el texto al arrastrar
    //             e.preventDefault();
    //         }
    //     };

    //     // Manejador del evento mouseup
    //     const handleMouseUp = () => {
    //         if (isDragging) {
    //             isDragging = false;
    //             startContainer = null;
    //         }
    //     };

    //     // Manejador del evento mousemove
    //     const handleMouseMove = (e) => {
    //         if (!isDragging) return;

    //         const containers = content_bonus.querySelectorAll('.bonus_container');
    //         containers.forEach((container) => {
    //             const rect = container.getBoundingClientRect();
    //             const inBounds = e.clientY > rect.top && e.clientY < rect.bottom;
    //             if (inBounds && !selectedContainers.has(container)) {
    //                 selectedContainers.add(container);
    //                 toggleCheckboxInContainer(container, true); // Marcar checkbox al pasar sobre el contenedor
    //             }
    //         });
    //     };

    //     // Event listeners para la selección arrastrando sobre contenedores
    //     content_bonus.addEventListener('mousedown', handleMouseDown);
    //     window.addEventListener('mouseup', handleMouseUp);
    //     window.addEventListener('mousemove', handleMouseMove);
    // };

        // Función que maneja la selección de checkboxes arrastrando sobre los contenedores con visualización del área de arrastre
        const handleDragSelection = () => {
            let isDragging = false;
            let startX = 0;
            let startY = 0;
            const selectedContainers = new Set();
    
            // Función para seleccionar/deseleccionar el checkbox dentro del contenedor
            const toggleCheckboxInContainer = (container, shouldCheck) => {
                const checkbox = container.querySelector('input[type="checkbox"]');
                if (checkbox && !checkbox.disabled) {
                    checkbox.checked = shouldCheck;
                    updateBonusContainerColor(checkbox);
                }
            };
    
            // Manejador del evento mousedown
            const handleMouseDown = (e) => {
                // Si el clic es directamente sobre el checkbox, no activamos la lógica de arrastre
                if (e.target.tagName === 'INPUT' && e.target.type === 'checkbox') {
                    return;
                }
    
                const container = e.target.closest('.bonus_container');
                if (container) {
                    isDragging = true;
                    startX = e.clientX;
                    startY = e.clientY;
    
                    selectedContainers.clear();
                    selectedContainers.add(container);
    
                    toggleCheckboxInContainer(container, true); // Marcar checkbox al iniciar arrastre
    
                    // Crear la caja de selección
                    drawSelectionBox(startX, startY, startX, startY);
    
                    // Evita seleccionar el texto al arrastrar
                    e.preventDefault();
                }
            };
    
            // Manejador del evento mouseup
            const handleMouseUp = () => {
                if (isDragging) {
                    isDragging = false;
                    removeSelectionBox();
                }
            };
    
            // Manejador del evento mousemove
            const handleMouseMove = (e) => {
                if (!isDragging) return;
    
                const currentX = e.clientX;
                const currentY = e.clientY;
    
                // Actualizar la caja de selección a medida que el ratón se mueve
                drawSelectionBox(startX, startY, currentX, currentY);
    
                const containers = content_bonus.querySelectorAll('.bonus_container');
                containers.forEach((container) => {
                    const rect = container.getBoundingClientRect();
                    const inBounds = rect.top < currentY && rect.bottom > currentY &&
                                     rect.left < currentX && rect.right > currentX;
    
                    if (inBounds && !selectedContainers.has(container)) {
                        selectedContainers.add(container);
                        toggleCheckboxInContainer(container, true); // Marcar checkbox al pasar sobre el contenedor
                    }
                });
            };
    
            // Event listeners para la selección arrastrando sobre contenedores
            content_bonus.addEventListener('mousedown', handleMouseDown);
            window.addEventListener('mouseup', handleMouseUp);
            window.addEventListener('mousemove', handleMouseMove);
        };

    // Aplica el color inicial a los checkboxes seleccionados cuando la página carga
    const applyInitialStyles = () => {
        const checkboxes = content_bonus.querySelectorAll( 'input[type="checkbox"]' );
        checkboxes.forEach((checkbox) => {
            updateBonusContainerColor(checkbox); // Aplica el color inicial
        });
    };

    // Cambia el color cuando los checkboxes cambian de estado
    const changeColorOnCheckboxChange = () => {
        const checkboxes = content_bonus.querySelectorAll( 'input[type="checkbox"]' );
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', function () {
                updateBonusContainerColor(this); // Cambia el color cuando cambia el estado
            });
        });
    };

    // Lógica para preguntar y confirmar si añadir o quitar bonificación
    const callServiceCheckbox = () => {
        const checkboxes = content_bonus.querySelectorAll('input[type="checkbox"]');
        const btnSendBonus = document.getElementById('btn_send_bonus');
        
        // Almacenar el estado inicial de cada checkbox al cargar la página
        const initialStates = Array.from(checkboxes).reduce((acc, checkbox) => {
            acc[checkbox.id] = checkbox.checked;
            return acc;
        }, {});
    
        console.log('Estado inicial de los checkboxes:', initialStates);
        console.log('Botón de bonificaciones encontrado:', btnSendBonus);
    
        // Al hacer clic en el botón, enviamos todas las peticiones en paralelo solo para los checkboxes cuyo estado ha cambiado
        if (!btnSendBonus) return;

        btnSendBonus.addEventListener('click', async () => {
            console.log('Botón de bonificaciones presionado');
    
            const idsToUpdate = [];
    
            checkboxes.forEach((checkbox) => {
                const id_booking = checkbox.id;
                const currentState = checkbox.checked;
                const initialState = initialStates[id_booking];
    
                // Si el estado ha cambiado (de marcado a desmarcado o viceversa), añadimos el id a la lista de peticiones
                if (currentState !== initialState) {
                    idsToUpdate.push(id_booking);
                }
            });
    
            console.log('ids que han cambiado de estado:', idsToUpdate);
    
            if (idsToUpdate.length === 0) {
                await alertGeneric('Sin cambios', 'No se ha modificado el estado de ningúna bonificación.', 2000, false, false, 'info');
                return;
            }
    
            // Confirmar si desea modificar las bonificaciones
            const result = await alertConfirm('¿Estás seguro?', '¿Deseas modificar las bonificaciones?', 'Sí, modificar!', 'Cancelar');
            if (result.isConfirmed) { 
    

                // Crear un array de promesas para cada id que cambió de estado
                const promises = idsToUpdate.map((id) => {
                    return new Promise(async (resolve, reject) => {
                        try {
                            const res = await fetchData(`${window.location.origin}/aforo/put-bonus/${data_ubicacion}/${id}`);
                            
                            // Verificar si la respuesta es exitosa
                            if (res.status === true || res.status === false) {
                                resolve(); // Resolvemos la promesa si la petición fue exitosa
                            } else {
                                reject(`Error al modificar la bonificación para el id: ${id}`);
                            }
                        } catch (error) {
                            console.error(`Error modificando las bonificaciones para el id ${id}:`, error);
                            await alertGeneric('Error', 'Error al modificar las bonificaciones', 'error');
                            reject(`Error al modificar la bonificación para el id: ${id}`);
                        }
                    });
                });
    
                // Ejecutar todas las promesas en paralelo usando Promise.all
                try {
                    await Promise.all(promises);
                    console.log('Bonificaciones modificadas correctamente');
                    await alertGeneric('Bonificaciones modificadas', 'Bonificaciones han sido modificadas correctamente.', 2500, false, false, 'success');
                    
                    // Refrescar la página para ver los cambios
                    setTimeout(() => {
                        window.location.reload();
                    } , 2000);
                } catch (error) {
                    console.error('Error en una o más peticiones:', error);
                    await alertGeneric('Error', 'Hubo un error al modificar una o más bonificaciones.', 'error');
                }
            }
        });
    };

    // Ejecutar las funciones necesarias al cargar la página
    applyInitialStyles();       // Aplicar colores iniciales
    changeColorOnCheckboxChange(); // Cambiar colores cuando se seleccionan/desmarcan
    callServiceCheckbox();      // Confirmar bonificaciones
    handleDragSelection();      // Selección arrastrando
};

export default bonusBookingFunctions;